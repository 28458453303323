import { Injectable } from '@angular/core';
import { environment as env } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})

export class OrganizationManagementService {

  domain: string = env.config.domain;
  orgKey: string = env.config.orgKey;

  constructor(readonly http: HttpClient) { }

  public getDiscourseURL() {
    return this.http.get(this.domain + env.discourseApiLink + '/' + this.orgKey);
  }

  public getOrganization() {
    return this.http.get(this.domain + env.getOrganization + '/' + this.orgKey);
  }

}
