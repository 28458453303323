import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { TermsConditionsComponent } from './layout/unauth/terms-conditions/terms-conditions.component';
import { RoleGuard } from './services/role.guard';
import { TcGuard } from './services/tc.guard';
import { environment as env } from '../environments/environment';
import { LogoutComponent } from './logout/logout.component';
import { MainComponent } from './layout/main/main.component';
import { UnauthComponent } from './layout/unauth/unauth.component';
import { IsAdminGuard } from './services/authguard.service';
import { FileProgressGuard } from './services/file-progress.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    canActivate: [FileProgressGuard],
    component: LogoutComponent
  },
  {
    path: 'm',
    component: MainComponent,
    data: { breadcrumb: '' },
    children: [
      {
        path: 'applobby',
        loadChildren: () => import('../../projects/dashboard/src/app/app.module').then(m => m.AppModule),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard],
        data: {
          selected_menu: ['Dashboard'],
          allowedRoles: [
            env.config.roles.XCAP_PLATFORM_ADMIN,
            env.config.roles.starcap_platform_admin,
            env.config.roles.imcore_scientific_director,
            env.config.roles.inspector_gadget,
            env.config.roles.imcore_member,
            env.config.roles.imcore_member_data_ingress_only,
            env.config.roles.data_controller,
            env.config.roles.imcore_guest,
            env.config.roles.site_dpo_gpo
          ]
        }
      },
      {
        path: 'projects',
        loadChildren: () => import('../../projects/studies/src/app/app.module').then(m => m.AppModule),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard],
        data: {
          selected_menu: ['Studies'],
          breadcrumb: 'Studies', path: 'm/studies', title: 'Studies',
          allowedRoles: [
            env.config.roles.imcore_scientific_director,
            env.config.roles.inspector_gadget,
            env.config.roles.imcore_member,
            env.config.roles.imcore_member_data_ingress_only,
            env.config.roles.data_controller,
            env.config.roles.site_dpo_gpo,
          ]
        }
      },
      {
        path: 'organization',
        loadChildren: () => import('../../projects/orgmanagement/src/app/app.module').then(m => m.AppModule),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard],
        data: {
          selected_menu: ['Organization'],
          breadcrumb: 'organization', path: 'organization',
          allowedRoles: [
            env.config.roles.XCAP_PLATFORM_ADMIN,
            env.config.roles.starcap_platform_admin
          ]
        }
      },
      {
        path: 'inbox',
        loadChildren: () => import('../../projects/inbox/src/app/app.module').then(m => m.AppModule),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard],
        data: {
          selected_menu: ['Inbox'],
          breadcrumb: 'Inbox', path: 'inbox',
          allowedRoles: [
            env.config.roles.XCAP_PLATFORM_ADMIN,
            env.config.roles.starcap_platform_admin,
            env.config.roles.data_controller,
            env.config.roles.site_dpo_gpo,
            env.config.roles.imcore_scientific_director
          ]
        }
      },
      {
        path: 'audit',
        loadChildren: () => import('../../projects/audit/src/app/app.module').then(m => m.AppModule),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard],
        data: {
          selected_menu: ['Study audit'],
          breadcrumb: 'Study Audit', path: 'audit',
          allowedRoles: [
            env.config.roles.site_dpo_gpo
          ]
        }
      },
      {
        path: 'users',
        loadChildren: () => import('../../projects/users/src/app/app.module').then(m => m.AppModule),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard],
        data: {
          selected_menu: ['Users'],
          breadcrumb: 'Users', path: 'usermanagement',
          allowedRoles: [
            env.config.roles.XCAP_PLATFORM_ADMIN,
            env.config.roles.starcap_platform_admin
          ]
        }
      },
      {
        path: 'pousers',
        loadChildren: () => import('../../projects/users/src/app/app.module').then(m => m.AppModule),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard],
        data: {
          selected_menu: ['Users'],
          breadcrumb: 'Users', path: 'usermanagement',
          allowedRoles: [
            env.config.roles.imcore_scientific_director,
            env.config.roles.inspector_gadget,
            env.config.roles.imcore_member,
            env.config.roles.imcore_member_data_ingress_only,
            env.config.roles.data_controller
          ]
        }
      },
      {
        path: 'datacatalog',
        loadChildren: () => import('../../projects/datacatalog/src/app/data-catalog.module').then(m => m.DataCatalogModule),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard],
        data: {
          selected_menu: ['Data'],
          breadcrumb: 'Data', path: 'datacatalog',
          allowedRoles: [
            env.config.roles.imcore_member,
            env.config.roles.imcore_member_data_ingress_only,
            env.config.roles.data_controller,
          ]
        }
      },
      {
        path: 'nf',
        pathMatch: 'full',
        component: NotfoundComponent
      }
    ]
  },
  {
    path: 'u',
    component: UnauthComponent,
    children: [
      {
        path: '',
        component: TermsConditionsComponent,
        data: { breadcrumb: 'TermsConditions', path: 'termsconditions' }
      },
    ]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'm/nf'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      canceledNavigationResolution: 'computed',
    }
  )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
